.picture-container {
  position: relative;
  width: 100%; /* Adjust as needed */
  
 
  overflow: hidden; /* Ensure the image and text overlay fit within the container */
 margin-bottom: 30px;
  box-shadow: 0 4px 8px rgba(3, 45, 60, 0.1); /* Soft shadow for depth */
}

.picture-image {
  width: 100%;
  height: 400px; /* Set a fixed height */
  object-fit: cover; /* Cover the entire container */
  display: block;
}

.text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Translucent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white; /* Ensure text is visible on the image */
  padding: 20px;
  box-sizing: border-box; /* Include padding in the element's total width and height */
  text-align: center; /* Center-align text within the overlay */
}

.title {
  font-size: 4.0em;
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: 700; /* Bold title */
}

.subtitle {
  font-size: 1.4em;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 400; /* Regular weight for subtitles */
  line-height: 1.4; /* Improved readability */
}

@media (max-width: 600px) {
  body{
    margin: 0;
    padding: 0;
  }
  .picture-image {
    height: 300px; /* Adjust height for smaller screens */
  }

  .title {
    font-size: 2em; /* Adjust title size for smaller screens */
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .subtitle {
    font-size: 15px; /* Adjust subtitle size for smaller screens */
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
